import { Route, Routes, useLocation } from 'react-router-dom';
import PageNotFoundView from './PageNotFoundView';
import Main from './Pages/Main';
import Childhood from './Pages/Childhood';
import Education from './Pages/Education';
import Work from './Pages/Work';
import Hobbies from './Pages/Hobbies';
import Mission from './Pages/Mission';
import Contacts from './Pages/Contacts';
import { useCallback, useEffect } from 'react';
import trackPathForAnalytics from './GAAnalytics';
import ToolMain from './Pages/WebTool/ToolMain';
import RichardQuiz from './Pages/RichardQuiz/RichardQuiz';

function App() {

  const { pathname, search } = useLocation();

  const analytics = useCallback(() => {
    trackPathForAnalytics({ path: pathname, search: search, title: pathname.split("/")[1] });
  }, [pathname, search]);

  useEffect(() => {
    analytics();
  }, [analytics]);

  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/no-page-found" element={<PageNotFoundView />} />

      <Route path="/childhood" element={<Childhood />} />
      <Route path="/education" element={<Education />} />
      <Route path="/work" element={<Work />} />
      <Route path="/hobbies" element={<Hobbies />} />
      <Route path="/mission" element={<Mission />} />
      <Route path="/contacts" element={<Contacts />} />

      <Route path="/writter-tool" element={<ToolMain />} />
      <Route path="/richard-quiz" element={<RichardQuiz />} />

    </Routes>
  );
}

export default App;