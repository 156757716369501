import React, { useState, useEffect, useRef } from 'react';
import { questions } from './questions';
import './RichardQuiz.css';
import RichardHeader from './RichardHeader';

function RichardQuiz() {
    const totalQuestions = questions.reduce((acc, section) => acc + section.items.length, 0);

    const [answers, setAnswers] = useState(Array(totalQuestions).fill(null));
    const [showResult, setShowResult] = useState(false);
    const [score, setScore] = useState(0);
    const [isAllAnswered, setIsAllAnswered] = useState(false);

    // Create a ref for the user-result-container
    const resultRef = useRef(null);

    // useEffect(() => {
    //     const randomValues = Array.from({ length: totalQuestions }, () => Math.floor(Math.random() * 6));
    //     setAnswers(randomValues);
    // }, [totalQuestions]);

    useEffect(() => {
        setIsAllAnswered(answers.every((answer) => answer !== null));
    }, [answers]);

    const handleAnswer = (index, value) => {
        const updatedAnswers = [...answers];
        updatedAnswers[index] = value;
        setAnswers(updatedAnswers);
    };

    const calculateScore = () => {
        let totalScore = 0;
        let index = 0;

        questions.forEach((section) => {
            section.items.forEach((question) => {
                const answer = answers[index];
                const questionScore = answer * question.multiplier * section.multiplier;
                totalScore += questionScore;
                index++;
            });
        });

        setScore(totalScore / 60);
        setShowResult(true);

        // Scroll the user-result-container into view
        setTimeout(() => {
            const offset = -150; // Negative offset to adjust scroll position
            const elementPosition = resultRef.current.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition + offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });
        }, 100);
    };

    const getSpeakerLevel = () => {
        if (score < 3.0) return 'Emerging Speaker';
        if (score < 3.5) return 'Good Speaker';
        if (score < 4.0) return 'Highly Competent Speaker';
        if (score < 4.25) return 'Excellent Speaker';
        if (score < 4.5) return 'Expert Speaker';
        if (score < 4.75) return 'World Class Speaker';
        return 'Shake The World Master Speaker';
    };

    const renderQuestions = () =>
        questions.map((section, sectionIndex) => (
            <div key={sectionIndex} className="section-block">
                <h3 className="section-header">{section.label}</h3>
                {section.items.map((question, questionIndex) => {
                    const index = questions
                        .slice(0, sectionIndex)
                        .reduce((acc, sec) => acc + sec.items.length, 0) + questionIndex;

                    return (
                        <div key={index} className="question">
                            <div className="question-text-container">
                                <p className="question-numbr-text">{index + 1}.</p>
                                <p className="question-text">{question.text}</p>
                            </div>
                            <div className="options">
                                {['1', '2', '3', '4', '5'].map((label) => (
                                    <label key={label} className="radio-label">
                                        <input
                                            type="radio"
                                            name={`question-${index}`}
                                            value={label}
                                            checked={answers[index] === parseInt(label)}
                                            onChange={() => handleAnswer(index, parseInt(label))}
                                        />
                                        {label}
                                    </label>
                                ))}
                            </div>
                        </div>
                    );
                })}
            </div>
        ));

    return (
        <div className="quiz-container">
            <RichardHeader />
            <header className="page-title-header">
                <h1>DO YOUR WORDS SHAKE THE WORLD?</h1>
                <h2>The 60 Metrics of a World Class Speaker</h2>
            </header>

            <section className="grading-instructions">
                <h3>Grade Yourself As Follows</h3>
                <div className="grading-options">
                    <div className="grading-option">
                        <span className="points-label">1 Point</span>
                        <span className="grading-description">NEVER</span>
                    </div>
                    <div className="grading-option">
                        <span className="points-label">2 Points</span>
                        <span className="grading-description">RARELY</span>
                    </div>
                    <div className="grading-option">
                        <span className="points-label">3 Points</span>
                        <span className="grading-description">SOMETIMES</span>
                    </div>
                    <div className="grading-option">
                        <span className="points-label">4 Points</span>
                        <span className="grading-description">OFTEN</span>
                    </div>
                    <div className="grading-option">
                        <span className="points-label">5 Points</span>
                        <span className="grading-description">ALWAYS</span>
                    </div>
                </div>
            </section>

            {!showResult ? (
                <div>
                    <main className="questions">{renderQuestions()}</main>
                    <button
                        className="done-button"
                        onClick={calculateScore}
                        disabled={!isAllAnswered}
                    >
                        Done
                    </button>
                </div>
            ) : (
                <div className="result-block" ref={resultRef}>
                    <div className="user-result-container">
                        <p className="speaker-level">Your Speaker Level:</p>
                        <h2 className="speaker-level-value">{getSpeakerLevel()}</h2>
                        <p>Total Score: <strong>{score.toFixed(2)}</strong></p>
                        <button
                            className="submit-button"
                            onClick={() => {
                                setAnswers(Array(totalQuestions).fill(null));
                                setShowResult(false);
                            }}
                        >
                            Retake Quiz
                        </button>
                    </div>
                </div>
            )}

            <div className="results-options-container">
                <h3>LEVELS</h3>
                <div className="results-options">
                    <div className="results-option">
                        <span className="points-label">2.50 - 3.00 Points</span>
                        <span className="grading-description">EMERGING SPEAKER</span>
                    </div>
                    <div className="results-option">
                        <span className="points-label">3.00 - 3.50 Points</span>
                        <span className="grading-description">GOOD SPEAKER</span>
                    </div>
                    <div className="results-option">
                        <span className="points-label">3.50 - 4.00 Points</span>
                        <span className="grading-description">HIGHLY COMPETENT SPEAKER</span>
                    </div>
                    <div className="results-option">
                        <span className="points-label">4.00 - 4.25 Points</span>
                        <span className="grading-description">EXCELLENT SPEAKER</span>
                    </div>
                    <div className="results-option">
                        <span className="points-label">4.25 - 4.50 Points</span>
                        <span className="grading-description">EXPERT SPEAKER</span>
                    </div>
                    <div className="results-option">
                        <span className="points-label">4.50 - 4.75 Points</span>
                        <span className="grading-description">WORLD CLASS SPEAKER</span>
                    </div>
                    <div className="results-option">
                        <span className="points-label">4.75 - 5.00 Points</span>
                        <span className="grading-description">SHAKE THE WORLD MASTER SPEAKER</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RichardQuiz;
