export const questions = [
    {
        label: "Getting Started",
        multiplier: 1,
        items: [
            { text: "I love to speak in public.", multiplier: 1 },
            { text: 'I look forward to being in front of an audience to be able to share things that I am "authentically passionate" about.', multiplier: 1 },
            { text: 'I get myself "in the zone" before I speak by exercising, breathing, centering, warming up so that I am loose and ready to go as I walk up to the front of the room.', multiplier: 1 },
            { text: "I look in the eyes of the person who introduces me and thank them in a conversational way.", multiplier: 1 },
            { text: "I acknowledge the person who introduces me and thank him or her.", multiplier: 1 },
            { text: "I acknowledge the audience and thank them for inviting me, etc.", multiplier: 1 },
            { text: "I ask the audience actual questions, either at the very beginning or at various times during the presentation to get them fully engaged.", multiplier: 1 },
            { text: "I ask the audience rhetorical questions, either at the very beginning or at various times during the presentation to get them fully engaged.", multiplier: 1 },
        ],
    },
    {
        label: "Secret #1: Storytelling",
        multiplier: 1,
        items: [
            { text: "I tell stories or case histories to illustrate my message, my product, service, or brand.", multiplier: 1 },
            { text: "I tell stories in the PRESENT tense.", multiplier: 1 },
            { text: "I tell stories with lots of dialogue.", multiplier: 1 },
            { text: "I use easy to understand, user-friendly language that anyone can understand, no matter how complicated the content is.", multiplier: 1 },
            { text: "If I use jargon or acronyms or challenging terms, I define them in a way that everyone can understand.", multiplier: 1 },
        ],
    },
    {
        label: "Secret #2: Voice Variation",
        multiplier: 1,
        items: [
            { text: "I understand that the impact of my presentation is generated even more by the tone of my voice than by the specific words I use.", multiplier: 1 },
            { text: "I vary my voice tone.", multiplier: 1 },
            { text: "I vary the volume of my voice tone. Sometimes louder, sometimes softer.", multiplier: 1 },
            { text: "I vary the pace of my voice tone. Sometimes faster, sometimes slower.", multiplier: 1 },
            { text: 'I vary the "pitch" of my voice tone. Sometimes higher, sometimes lower.', multiplier: 1 },
            { text: "I punctuate the most important words in my sentences.", multiplier: 1 },
            { text: "I punctuate at least one word in every paragraph I speak.", multiplier: 1 },
            { text: "To really drive the point, I punctuate each syllable in a multi-syllable word like very, always, never.", multiplier: 1 },
            { text: "The resonance of my voice is full enough to add gravitas and authority to my message.", multiplier: 1 },
            { text: "I pause to allow the audience to digest and to build a sense of engagement and drama.", multiplier: 1 },
        ],
    },
    {
        label: "Secret #3: Body Language",
        multiplier: 1,
        items: [
            { text: "I understand that the impact of my presentation is generated even more by my body language than by the specific words I use.", multiplier: 1 },
            { text: "I 'read the room' and tune into the audience’s body language to see how engaged they are.", multiplier: 1 },
            { text: "I stand up straight with my shoulders up.", multiplier: 1 },
            { text: "When I want to make an authoritative point, I gesture downwards.", multiplier: 1 },
            { text: 'When I want to make an authoritative point, I gesture downwards within "The Power Zone", that is, between my shoulders.', multiplier: 1 },
            { text: "I make a point to make eye contact with as many people as I can, as if I was having a conversation with them.", multiplier: 1 },
            { text: "In a small audience I make direct eye contact with everyone, at least once.", multiplier: 1 },
            { text: "In large audiences, I make sure to make eye contact with at least one person in each area of the audience.", multiplier: 1 },
            { text: "I notice when audience members are distracted by their cell phones or otherwise disconnecting, and I adjust my presentation to re-engage them.", multiplier: 1 },
            { text: "When it is possible to leave the podium, I move around the stage/room in a relaxed, conversational way.", multiplier: 1 },
            { text: "I am physically balanced when I stand and move and mentally centered when I speak, i.e., clear and non-flustered.", multiplier: 1 },
            { text: "I fill the room with my energy so that the people in the very last row can hear me easily and also feel my presence.", multiplier: 1 },
        ],
    },
    {
        label: "Secret #4: Compelling Messaging",
        multiplier: 1,
        items: [
            { text: 'I summarize all points into a "Lasered, Compelling Message" that captures the essence of my presentation.', multiplier: 1 },
            { text: 'Instead of "inductively" building my case (1 + 1 + 1 + 1 + 1), I "deductively" start with the main message.', multiplier: 1 },
            { text: "I focus on what is unique about my product or message and how it benefits my audience.", multiplier: 1 },
            { text: "I develop my message based on what I am authentically passionate about.", multiplier: 1 },
            { text: "If I am not delivering a fully scripted speech, I translate the content into a bullet-point outline.", multiplier: 1 },
            { text: "My PowerPoint presentations have one main point per slide, with simple messages or graphs.", multiplier: 1 },
        ],
    },
    {
        label: "Secret #5: Conversational Speaking",
        multiplier: 1,
        items: [
            { text: "Even when I have to read prepared text, I stay conversational and engaging.", multiplier: 1 },
            { text: 'I use fillers like "as you know" or "now this is important" to personalize my delivery.', multiplier: 1 },
            { text: "(Triple Score) I look at my speaking opportunities not as performances at an audience, but rather as conversations WITH my audience.", multiplier: 3 },
            { text: "I recognize that even without speaking, the audience communicates through body language.", multiplier: 1 },
            { text: "I embrace mistakes and unexpected events as opportunities to connect with the audience.", multiplier: 1 },
        ],
    },
    {
        label: "Secret #6: Passion and Energy",
        multiplier: 1,
        items: [
            { text: "I include energy and excitement in my presentations.", multiplier: 1 },
            { text: "I include feeling and passion in my presentations.", multiplier: 1 },
            { text: "There is a clear narrative or storyline in my presentations.", multiplier: 1 },
            { text: "I use data, facts, and analysis to provide a solid foundation for my message.", multiplier: 1 },
            { text: "I vary the pace throughout the presentation.", multiplier: 1 },
            { text: "I focus on what excites me in my presentations.", multiplier: 1 },
            { text: "I focus on what fascinates me in my presentations.", multiplier: 1 },
            { text: "I emphasize the specifics of what fascinates me.", multiplier: 1 },
            { text: "I focus on what moves me or what I feel passionate about.", multiplier: 1 },
        ],
    },
    {
        label: "Secret #7: Authenticity",
        multiplier: 1,
        items: [
            { text: 'I ask myself what is "So Fricken Cool" about my topic to inspire passion.', multiplier: 1 },
            { text: "I see speaking as an opportunity to contribute to the world.", multiplier: 1 },
            { text: "I love sharing things I am authentically passionate about.", multiplier: 1 },
        ],
    },
];
