import React from 'react';
import './RichardHeader.css';

const RichardHeader = () => {
    return (
        <header className="tm-header">
            <nav className="uk-navbar">
                {/* Left: Logo */}
                <div className="uk-navbar-left">
                    <a href="/richard-quiz" className="uk-navbar-item uk-logo">
                        RICHARD GREENE
                    </a>
                </div>

                {/* Right: Navigation and Booking Button aligned horizontally */}
                <div className="uk-navbar-right">
                    <div className="navbar-links">
                        <a href="/richard-quiz">About Richard</a>
                        <a href="/richard-quiz">Personal Training</a>
                        <a href="/richard-quiz">Lectures & Seminars</a>
                    </div>
                    <a href="/richard-quiz" className="booking-button">
                        Booking
                    </a>
                </div>
            </nav>
        </header>
    );
};

export default RichardHeader;
